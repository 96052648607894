'use client'; // Error components must be Client Components

import { useEffect } from 'react';

export default function Error({
    error,
    reset,
}: {
    error: Error;
    reset: () => void;
}) {
    useEffect(() => {
        console.error(error);
    }, [error]);

    return (
        <main>
            <section className="ptb-100 bg-image full-height" data-overlay="8">
                <div className="background-image-wraper"/>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-9 col-lg-7">
                            <div className="error-content-wrap text-center text-white">
                                <div className="notfound-404">
                                    <h1 className="text-white">ERROR</h1>
                                </div>
                                <h2 className="text-white">Something went wrong!</h2>
                                <p className="lead">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                                <button onClick={() => reset()}>Try again</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
